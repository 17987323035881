/**
 *  views.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO
 *  @description: Controllador de las vistas con las rutas
 * 	@process: 3
 */

import React from "react";
import Routes from "./routes";

const View = () => <Routes></Routes>;

export default View;
