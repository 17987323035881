/**
 *  routes.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO
 *  @description: Manejo de todas las rutas de la aplicación
 * 	@process: 4
 */

import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

// Conexión con el Context API
import { GlobalContext } from "../context";

// Páginas Web
import Login from "../pages/login";
import Orders from "../pages/orders";
import Interactions from "../pages/interactions";
import Comments from "../pages/comments";
import PasswordForgot from "../pages/passwordForgot";
import PasswordReset from "../pages/passwordReset";

class Routes extends Component {
  renderRoutes() {
    let routesRender = [];

    routesRender.push(<Route exact path={"/"} component={Login} key={"/"} />);
    routesRender.push(
      <Route exact path={"/login"} component={Login} key={"/login"} />
    );
    routesRender.push(
      <Route exact path={"/orders"} component={Orders} key={"/orders"} />
    );
    routesRender.push(
      <Route
        exact
        path={"/interactions"}
        component={Interactions}
        key={"/interactions"}
      />
    );
    routesRender.push(
      <Route exact path={"/comments"} component={Comments} key={"/comments"} />
    );
    routesRender.push(
      <Route
        exact
        path={"/password/forgot"}
        component={PasswordForgot}
        key={"password/forgot"}
      />
    );
    routesRender.push(
      <Route
        exact
        path={"/password/reset/:token"}
        component={PasswordReset}
        key={"password/reset"}
      />
    );

    return routesRender;
  }

  render() {
    return (
      <GlobalContext>
        <BrowserRouter>
          <div className="flex main">
            <div className="column full">
              <Switch>{this.renderRoutes()}</Switch>
            </div>
          </div>
        </BrowserRouter>
      </GlobalContext>
    );
  }
}

export default Routes;
