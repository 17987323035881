/**
 *  header.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO
 *  @description: Componente header
 */

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Consumer } from "../../context";
import Rightbar from "./rightbar";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        idProveedor: 0,
        idServicio: 0,
        nombre: "",
        titulo: "",
        descripcion: "",
        telefono: "",
        whatsapp: "",
        web: "",
        igate: false,
        calificacion: 0,
        ciudad: "",
        logo: "",
        usuario: ""
      },
      denied: false,
      Logo:
        process.env.REACT_APP_NAME === "iGate"
          ? require("../../img/logo.png")
          : require("../../img/logo-AppMosphera-r.png")
    };
  }

  componentDidMount() {
    this.load();

    switch (this.props.nav) {
      case "pedidos":
        document.getElementById(this.props.nav).classList.add("nav-active");
        break;
      case "interacciones":
        document.getElementById(this.props.nav).classList.add("nav-active");
        break;
      case "comentarios":
        document.getElementById(this.props.nav).classList.add("nav-active");
        break;
      default:
        break;
    }
  }

  async load() {
    const user = await this.props.context.loadUser();

    if (user) {
      this.setState({
        user: {
          idProveedor: user.id.id_Proveedor,
          idServicio: user.id.id_Servicio,
          nombre: user.id.Nombre,
          titulo: user.id.Titulo,
          descripcion: user.id.Descripcion,
          telefono: user.id.Telefono,
          whatsapp: user.id.Whatsapp,
          web: user.id.Web,
          igate: user.id.iGate,
          calificacion: user.id.Calificacion,
          ciudad: user.id.Ciudad,
          logo: user.id.Logo,
          usuario: user.id.Usuario
        }
      });
    }
  }

  handleLogout() {
    this.props.context.logout();
    window.location = "/login";
  }

  render() {
    return (
      <React.Fragment>
        <header className="justify-center header-slide">
          <div className="row full">
            <div className="options row align-center justify-center">
              <div className="logo align-center">
                <Link to="/orders" className="responsive-img auto">
                  <img
                    src={this.state.Logo}
                    alt={`Logo ${process.env.REACT_APP_NAME}`}
                    title={`Logo ${process.env.REACT_APP_NAME}`}
                  />
                </Link>
                <p className="font-large weight-medium color-white">
                  &nbsp; {process.env.REACT_APP_NAME === "iGate" ? "iGate" : ""}
                </p>
              </div>
            </div>
            <div className="row full justify-end full ">
              <nav className="align-center justify-end full">
                {this.state.denied ? (
                  <div className="row justify-end">
                    <div className="nav-item auto">
                      <button
                        href="#one"
                        className="font-regular color-white"
                        onClick={this.handleLogout.bind(this)}
                      >
                        <i className="fas fa-sign-out-alt font-text" />
                        Salir
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="row justify-end">
                    <div className="nav-item auto text-center" id="pedidos">
                      <Link to="/orders" className="font-regular color-white">
                        <i className="fas fa-shipping-fast font-text" /> Pedidos
                      </Link>
                    </div>

                    <div
                      className="nav-item auto text-center"
                      id="interacciones"
                    >
                      <Link
                        to="/interactions"
                        className="font-regular color-white"
                      >
                        <i className="fas fa-mobile-alt font-text" />{" "}
                        Interacciones
                      </Link>
                    </div>
                    <div className="nav-item auto text-center" id="comentarios">
                      <Link to="/comments" className="font-regular color-white">
                        <i className="fas fa-comment font-text" /> Comentarios
                      </Link>
                    </div>
                    <div className="nav-item auto">
                      <a
                        onClick={() => {
                          const leftbar = document.getElementById(
                            "rightsidebar"
                          );
                          if (leftbar) {
                            leftbar.classList.toggle("open");
                          }
                        }}
                        href="#settings"
                        className="font-regular color-white"
                      >
                        <i className="fas fa-cogs font-text" />
                      </a>
                    </div>
                  </div>
                )}
              </nav>
            </div>
          </div>
        </header>
        <Rightbar />
      </React.Fragment>
    );
  }
}

export default Consumer(Header);
