/**
 *  app.js
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO
 *  @description: Punto de Entrada de la aplicación
 * 	@process: 2
 */

import React, { Component } from "react";
import Views from "./core/views";

class App extends Component {
  constructor(props) {
    super(props);

    process.env.REACT_APP_NAME === "iGate"
      ? require("./sass/style.scss")
      : require("./sass/styleAppMosphera.scss");
  }

  render() {
    return (
      <div className="App">
        <Views> </Views>
      </div>
    );
  }
}

export default App;
