/**
 *  users.js
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO
 *  @description: Manejador de ejecuciones del Context para Usuarios
 */

/* import Request from "../core/httpClient"; */

/* const req = new Request(); */

export async function loadUser() {
  let user = window.localStorage.getItem("iGateAdmin");

  if (user) {
    user = JSON.parse(user);
    /*  const res = await req.post('/users/tokuser', { id: user.id }); 

        if (res.data) {

            user.id = JSON.parse(res.data);

        } else {

            user.id = { id: 0, tipo: 0 }
        } */

    this.setState({ user: user });
    return user;
  }
}

export function login(data) {
  window.localStorage.setItem("iGateAdmin", JSON.stringify(data));
  this.setState({ user: data });
}

export function logout() {
  window.localStorage.setItem("iGateAdmin", '{"auth": false}');
  window.localStorage.removeItem("iGateAdmin");
  this.setState({ user: { auth: false } });
}
