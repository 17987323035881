import React, { Component } from "react";
import { Consumer } from "../../context";
import Rating from "react-rating";

class Rightbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        idProveedor: 0,
        idServicio: 0,
        nombre: "",
        titulo: "",
        descripcion: "",
        telefono: "",
        whatsapp: "",
        web: "",
        igate: false,
        calificacion: 0,
        ciudad: "",
        logo: "",
        usuario: ""
      }
    };
  }

  componentDidMount() {
    this.load();
  }

  async load() {
    const user = await this.props.context.loadUser();

    if (user) {
      this.setState({
        user: {
          idProveedor: user.id.id_Proveedor,
          idServicio: user.id.id_Servicio,
          nombre: user.id.Nombre,
          titulo: user.id.Titulo,
          descripcion: user.id.Descripcion,
          telefono: user.id.Telefono,
          whatsapp: user.id.Whatsapp,
          web: user.id.Web,
          igate: user.id.iGate,
          calificacion: user.id.Calificacion,
          ciudad: user.id.Ciudad,
          logo: user.id.Logo,
          usuario: user.id.Usuario
        }
      });
    }
  }

  handleLogout() {
    this.props.context.logout();
    window.location = "/login";
  }

  render() {
    return (
      <div id="rightsidebar" className="rightsidebar row">
        <div className="column container">
          <h3 className="accordion color-white">
            {this.state.user.nombre} {this.state.user.ciudad}
          </h3>
          <div className="white-space-16"></div>
          <div className="accordion justify-center ">
            <img
              src={this.state.user.logo}
              alt={`Logo ${this.state.user.nombre}`}
              title={`Logo ${this.state.user.nombre}`}
            />
          </div>
          <div className="white-space-16"></div>
          <div className="row-responsive">
            <div className="accordion align-center justify-start color-white">
              Calificación:
            </div>
            <div className="accordion align-center justify-end color-white">
              <span
                className="accordion color-white"
                title={`Calificación ${this.state.user.calificacion}`}
              >
                <Rating
                  readonly
                  initialRating={this.state.user.calificacion}
                  emptySymbol="far fa-star font-text color-warning"
                  fullSymbol="fas fa-star font-text color-warning"
                />
              </span>
            </div>
          </div>
          <div className="white-space-16"></div>
          <div className="accordion align-center justify-start color-white">
            <span className="accordion color-white" title="Teléfono">
              <i className="fas fa-phone font-text "></i>{" "}
              {this.state.user.telefono === ""
                ? "S/A"
                : this.state.user.telefono}
            </span>
          </div>
          <div className="white-space-16"></div>
          <div className="accordion align-center justify-start color-white">
            <span className="accordion color-white" title="Whatsapp">
              <i className="fab fa-whatsapp font-text  "></i>{" "}
              {this.state.user.whatsapp === ""
                ? "S/A"
                : this.state.user.whatsapp}
            </span>
          </div>
          <div className="white-space-16"></div>
          <div className="accordion justify-start " title="Página Web">
            <a
              href={this.state.user.web === "" ? "" : this.state.user.web}
              className="accordion color-white nav-exit"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fas fa-globe-americas font-text "></i>{" "}
              {this.state.user.web === "" ? "S/A" : this.state.user.web}
            </a>
          </div>
          <div className="white-space-16"></div>
          <div className="white-space-16"></div>
          <div className="accordion justify-center ">
            <a
              href="#one"
              className="font-regular color-white nav-exit"
              onClick={this.handleLogout.bind(this)}
            >
              <i className="fas fa-sign-out-alt font-text " /> Cerrar Sesión
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default Consumer(Rightbar);
