/**
 *  passwordForgot.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO
 *  @description: Página de peticion para restablecer contraseña contraseña
 */

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Consumer } from "../context";
import Request from "../core/httpClient";
const request = new Request();

class PasswordForgot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      LogoBlack:
        process.env.REACT_APP_NAME === "iGate"
          ? require("../img/logoblack.png")
          : require("../img/LogoAppMosph.png")
    };
  }

  async handleSubmit(event) {
    event.preventDefault();
    this.setState({ loading: true });
    const form = event.target;
    let data = { email: form.email.value };
    const response = await request.post("/proveedores/password/forgot", data);

    console.log(response);

    if (response && !response.error) {
      this.setState({
        empty: response.empty,
        loading: false,
        message: response.message
      });
    } else {
      this.setState({
        error: true,
        loading: false,
        message: response.message
      });
    }
  }

  async handleSubmitLogin(event) {
    window.location = "/login";
  }

  render() {
    return (
      <div className="login column">
        <Helmet>
          <title>{process.env.REACT_APP_NAME} - Restablecer contraseña</title>
        </Helmet>
        <div className="justify-center content">
          <div className="container align-center justify-center">
            <div className="card-login column">
              <div className="responsive-img justify-center">
                <img
                  src={this.state.LogoBlack}
                  alt={`Logo ${process.env.REACT_APP_NAME}`}
                  title={`Logo ${process.env.REACT_APP_NAME}`}
                />
              </div>
              <div className="white-space-8" />
              <h4 className="text-center">
                ¿Tienes problemas para iniciar sesión?
              </h4>
              <div className="white-space-8" />
              <h5 className="text-center">
                Ingresa tu correo electrónico y te enviaremos un enlace para
                recuperar el acceso a tu cuenta.
              </h5>
              <div className="white-space-8" />
              <form className="column" onSubmit={this.handleSubmit.bind(this)}>
                <div className="input-container">
                  <input
                    name="email"
                    type="email"
                    maxLength="64"
                    minLength="6"
                    autoComplete="ÑÖcompletes"
                    placeholder="Correo electrónico"
                    className="weight-semi text-center"
                    required
                  />
                </div>
                <div className="white-space-16" />
                <div className="btn-container">
                  <button type="submit" className="btn btn-primary color-white">
                    {this.state.loading ? (
                      <i className="fas fa-spinner fa-spin font-text" />
                    ) : (
                      <span className="color-white">
                        Enviar enlace de inicio de sesión
                      </span>
                    )}
                  </button>
                  <div className="white-space-8" />
                </div>
                {this.state.message ? (
                  <div className="column">
                    <div className="white-space-8" />
                    <p className="text-center">{this.state.message}</p>
                  </div>
                ) : null}
                <div className="white-space-16" />
              </form>
              <a
                className="justify-center"
                href="#one"
                onClick={this.handleSubmitLogin.bind(this)}
              >
                Volver al inicio de sesión
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Consumer(PasswordForgot));
