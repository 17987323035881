import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Header from "../components/header/header";
import moment from "moment";
import Request from "../core/httpClient";
import { Consumer } from "../context";
import Rating from "react-rating";
const req = new Request();

class Comments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      comments: [],
      page: 1,
      empty: true,
      user: {
        idProveedor: 0,
        idServicio: 0,
        nombre: "",
        titulo: "",
        descripcion: "",
        telefono: "",
        whatsapp: "",
        web: "",
        igate: false,
        calificacion: 0,
        ciudad: "",
        logo: "",
        usuario: ""
      }
    };
  }

  async componentDidMount() {
    await this.load();
    await this.getComments();
  }

  async load() {
    const user = await this.props.context.loadUser();

    if (user) {
      this.setState({
        user: {
          idProveedor: user.id.id_Proveedor,
          idServicio: user.id.id_Servicio,
          nombre: user.id.Nombre,
          titulo: user.id.Titulo,
          descripcion: user.id.Descripcion,
          telefono: user.id.Telefono,
          whatsapp: user.id.Whatsapp,
          web: user.id.Web,
          igate: user.id.iGate,
          calificacion: user.id.Calificacion,
          ciudad: user.id.Ciudad,
          logo: user.id.Logo,
          usuario: user.id.Usuario
        }
      });
    }
  }

  /*funcion del paginador*/
  setPage(page) {
    this.setState({ page });
  }

  startChange(event) {
    this.setState({ startDate: event.target.value });
  }

  endChange(event) {
    this.setState({ endDate: event.target.value });
  }

  async getComments(event) {
    if (event) {
      event.preventDefault();
    }

    this.setState({ loading: true });

    const data = {
      start: this.state.startDate,
      end: this.state.endDate,
      idProveedor: this.state.user.idProveedor
    };

    const res = await req.post("/proveedores/getComments", data);

    if (res.comments) {
      this.setState({ comments: res.comments, empty: false, loading: false });
    }

    if (res.empty) {
      this.setState({ empty: res.empty, loading: false });
    }
  }

  render() {
    return (
      <div className="admin column">
        <Helmet>
          <title>{process.env.REACT_APP_NAME} - Commentarios</title>
        </Helmet>
        <Header nav={"comentarios"} />
        <div className="row justify-center">
          <div className="column" id="content">
            <div className="justify-center">
              <div className="container column text-justify">
                <div className="white-space-32"></div>
                <div className="navigation align-center">
                  <div className="justify-start">
                    <h3 className="weight-semi">Comentarios</h3>
                  </div>
                </div>
                <div className="white-space-8"></div>
                <div className="filter-date row-responsive">
                  <form
                    className="get-reports row-responsive"
                    onSubmit={this.getComments.bind(this)}
                  >
                    <div className="data-from column">
                      <h5 className="color-black">DESDE:</h5>
                      <div className="white-space-8" />
                      <input
                        type="date"
                        id="startdate"
                        name="startdate"
                        className="input input-text"
                        placeholder="Desde"
                        value={this.state.startDate}
                        onChange={this.startChange.bind(this)}
                      />
                    </div>
                    <div className="data-to column">
                      <h5 className="color-black">HASTA:</h5>
                      <div className="white-space-8" />
                      <input
                        type="date"
                        id="enddate"
                        name="enddate"
                        className="input input-text"
                        placeholder="Hasta"
                        value={this.state.endDate}
                        onChange={this.endChange.bind(this)}
                      />
                    </div>
                    <div className="get-result justify-start align-end">
                      <button className="btn btn-filter btn-small">
                        {!this.props.loading ? (
                          <i className="fas fa-download" />
                        ) : (
                          <i className="fas fa-spinner fa-spin" />
                        )}
                        &nbsp; OBTENER
                      </button>
                    </div>
                  </form>
                  <div className="get-results justify-end align-end">
                    <div className="row-responsive">
                      Calificación:
                      <div className=" align-center justify-end">
                        <span
                          title={`Calificación ${this.state.user.calificacion}`}
                        >
                          <Rating
                            readonly
                            initialRating={this.state.user.calificacion}
                            emptySymbol="far fa-star font-text color-warning"
                            fullSymbol="fas fa-star font-text color-warning"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="white-space-16"></div>
                {this.state.loading ? (
                  <React.Fragment>
                    <div className="white-space-32"></div>
                    <div className="row justify-center">
                      <i className="fas fa-spinner fa-spin"></i>
                    </div>
                  </React.Fragment>
                ) : !this.state.empty ? (
                  <React.Fragment>
                    {this.state.comments.map((item, i) => {
                      return (
                        <div key={i}>
                          <div className="card-table">
                            <div className="row-responsive">
                              <span title={`Calificación ${item.calificacion}`}>
                                <Rating
                                  readonly
                                  initialRating={item.calificacion}
                                  emptySymbol="far fa-star font-text color-warning"
                                  fullSymbol="fas fa-star font-text color-warning"
                                />
                              </span>
                              <p>
                                &nbsp; Escrito el día{" "}
                                {moment(item.fecha).format("DD/MM/YYYY")}
                              </p>
                            </div>
                            <div className="white-space-16"></div>
                            <p>
                              {item.comentarios.length > 0
                                ? item.comentarios
                                : "Sin comentarios"}
                            </p>
                          </div>
                          <div className="white-space-16"></div>
                        </div>
                      );
                    })}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <div className="white-space-48"></div>
                    <div className="justify-center">
                      <h3>No se encontraron resultados.</h3>
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Consumer(Comments);
