/**
 *  login.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO
 *  @description: Página de Login
 */

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Consumer } from "../context";
import Request from "../core/httpClient";
const request = new Request();

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      user: {
        idProveedor: 0,
        idServicio: 0,
        nombre: "",
        titulo: "",
        descripcion: "",
        telefono: "",
        whatsapp: "",
        web: "",
        igate: false,
        calificacion: 0,
        ciudad: "",
        logo: "",
        usuario: ""
      },
      LogoBlack:
        process.env.REACT_APP_NAME === "iGate"
          ? require("../img/logoblack.png")
          : require("../img/LogoAppMosph.png")
    };
  }

  componentDidMount() {
    this.load();
  }

  async load() {
    const user = await this.props.context.loadUser();

    if (user) {
      this.setState({
        user: {
          idProveedor: user.id.id_Proveedor,
          idServicio: user.id.id_Servicio,
          nombre: user.id.Nombre,
          titulo: user.id.Titulo,
          descripcion: user.id.Descripcion,
          telefono: user.id.Telefono,
          whatsapp: user.id.Whatsapp,
          web: user.id.Web,
          igate: user.id.iGate,
          calificacion: user.id.Calificacion,
          ciudad: user.id.Ciudad,
          logo: user.id.Logo,
          usuario: user.id.Usuario
        }
      });

      this.props.history.push("/orders");
    }
  }

  async handleSubmit(event) {
    event.preventDefault();
    this.setState({ loading: true });
    const form = event.target;
    let data = { user: form.user.value, password: form.password.value };
    const response = await request.post("/proveedores/login", data);

    if (response && !response.error) {
      if (response.user && !response.empty) {
        this.props.context.login({ id: response.user, auth: true });
        this.props.history.push("/orders");
      } else {
        this.setState({
          empty: response.empty,
          loading: false,
          message: response.message
        });
      }
    } else {
      this.setState({
        error: true,
        loading: false,
        message: response.message
      });
    }
  }

  handleReset() {
    window.location = "password/forgot";
  }

  render() {
    return (
      <div className="login column">
        <Helmet>
          <title>{process.env.REACT_APP_NAME} - Login</title>
        </Helmet>
        <div className="justify-center content">
          <div className="container align-center justify-center">
            <div className="card-login column">
              <div className="responsive-img justify-center">
                <img
                  src={this.state.LogoBlack}
                  alt={`Logo ${process.env.REACT_APP_NAME}`}
                  title={`Logo ${process.env.REACT_APP_NAME}`}
                />
              </div>
              <div className="white-space-8" />
              <h3 className="text-center">Iniciar sesión</h3>
              <div className="white-space-16" />
              <form className="column" onSubmit={this.handleSubmit.bind(this)}>
                <div className="input-container">
                  <input
                    name="user"
                    type="text"
                    maxLength="64"
                    minLength="6"
                    autoComplete="ÑÖcompletes"
                    placeholder="Usuario"
                    className="weight-semi text-center"
                    required
                  />
                </div>
                <div className="white-space-8" />
                <div className="input-container">
                  <input
                    name="password"
                    type="password"
                    maxLength="64"
                    minLength="8"
                    autoComplete="ÑÖcompletes"
                    placeholder="Contraseña"
                    className="weight-semi text-center"
                    required
                  />
                </div>
                <div className="white-space-16" />
                <div className="btn-container">
                  <button type="submit" className="btn btn-primary color-white">
                    {this.state.loading ? (
                      <i className="fas fa-spinner fa-spin font-text" />
                    ) : (
                      <span className="color-white">
                        <i className="fas fa-sign-in-alt font-text" />
                        &nbsp; ACCEDER
                      </span>
                    )}
                  </button>
                </div>
                {this.state.message ? (
                  <div className="column">
                    <div className="white-space-8" />
                    <p className="text-center">{this.state.message}</p>
                  </div>
                ) : null}
                <div className="white-space-16" />
                <a
                  className="justify-center"
                  href="#one"
                  onClick={this.handleReset.bind(this)}
                >
                  Restablecer contraseña
                </a>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Consumer(Login));
