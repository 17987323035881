import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Header from "../components/header/header";
import Paginador from "../components/paginador/paginador";
import moment from "moment";
import ReactExport from "react-export-excel";
import Request from "../core/httpClient";
import { Consumer } from "../context";
const req = new Request();

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

class Orders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      data: [],
      page: 1,
      empty: true,
      user: {
        idProveedor: 0,
        idServicio: 0,
        nombre: "",
        titulo: "",
        descripcion: "",
        telefono: "",
        whatsapp: "",
        web: "",
        igate: false,
        calificacion: 0,
        ciudad: "",
        logo: "",
        usuario: ""
      }
    };
  }

  async componentDidMount() {
    await this.load();
    await this.getReport();
  }

  async load() {
    const user = await this.props.context.loadUser();

    if (user) {
      this.setState({
        user: {
          idProveedor: user.id.id_Proveedor,
          idServicio: user.id.id_Servicio,
          nombre: user.id.Nombre,
          titulo: user.id.Titulo,
          descripcion: user.id.Descripcion,
          telefono: user.id.Telefono,
          whatsapp: user.id.Whatsapp,
          web: user.id.Web,
          igate: user.id.iGate,
          calificacion: user.id.Calificacion,
          ciudad: user.id.Ciudad,
          logo: user.id.Logo,
          usuario: user.id.Usuario
        }
      });
    }
  }

  /*funcion del paginador*/
  setPage(page) {
    this.setState({ page });
  }

  startChange(event) {
    this.setState({ startDate: event.target.value });
  }

  endChange(event) {
    this.setState({ endDate: event.target.value });
  }

  async getReport(event) {
    if (event) {
      event.preventDefault();
    }

    this.setState({ loading: true });

    const data = {
      start: this.state.startDate,
      end: this.state.endDate,
      idProveedor: this.state.user.idProveedor
    };

    const res = await req.post("/servicios/getPedidos", data);

    if (res.reports) {
      this.setState({ data: res.reports, empty: false, loading: false });
    }

    if (res.empty) {
      this.setState({ empty: res.empty, loading: false });
    }
  }

  render() {
    let dataSet = [],
      columns = [],
      dataSetExcel = [];

    if (this.state.data.length !== 0) {
      columns = Object.keys(this.state.data[0]);
      dataSet = this.state.data.map((row, i) => {
        return (
          <tr className="table-row" key={i}>
            {columns.map((column, index) => (
              <td
                key={index}
                style={{
                  textAlign: column === "Cantidad" ? "right" : "left"
                }}
              >
                {column === "Fecha"
                  ? moment(row[column]).format("DD/MM/YYYY")
                  : row[column]}
              </td>
            ))}
          </tr>
        );
      });

      dataSetExcel = [
        {
          columns,
          data: this.state.data.map(row => {
            return columns.map(column =>
              column === "Fecha"
                ? moment(row[column]).format("DD/MM/YYYY")
                : row[column]
            );
          })
        }
      ];
    }

    return (
      <div className="admin column">
        <Helmet>
          <title>{process.env.REACT_APP_NAME} - Pedidos</title>
        </Helmet>
        <Header nav={"pedidos"} />
        <div className="row justify-center">
          <div className="column" id="content">
            <div className="justify-center">
              <div className="container column text-justify">
                <div className="white-space-32"></div>
                <div className="navigation align-center">
                  <div className="justify-start">
                    <h3 className="weight-semi">Pedidos</h3>
                  </div>
                </div>
                <div className="white-space-8"></div>
                <div className="filter-date row-responsive">
                  <form
                    className="get-reports row-responsive"
                    onSubmit={this.getReport.bind(this)}
                  >
                    <div className="data-from column">
                      <h5 className="color-black">DESDE:</h5>
                      <div className="white-space-8" />
                      <input
                        type="date"
                        id="startdate"
                        name="startdate"
                        className="input input-text"
                        placeholder="Desde"
                        value={this.state.startDate}
                        onChange={this.startChange.bind(this)}
                      />
                    </div>
                    <div className="data-to column">
                      <h5 className="color-black">HASTA:</h5>
                      <div className="white-space-8" />
                      <input
                        type="date"
                        id="enddate"
                        name="enddate"
                        className="input input-text"
                        placeholder="Hasta"
                        value={this.state.endDate}
                        onChange={this.endChange.bind(this)}
                      />
                    </div>
                    <div className="get-result justify-start align-end">
                      <button className="btn btn-filter btn-small">
                        {!this.props.loading ? (
                          <i className="fas fa-download" />
                        ) : (
                          <i className="fas fa-spinner fa-spin" />
                        )}
                        &nbsp; OBTENER
                      </button>
                    </div>
                  </form>
                  <div className="get-results justify-end align-end">
                    <ExcelFile
                      element={
                        <button
                          type="button"
                          className="btn btn-primary btn-small color-white"
                        >
                          <i className="far fa-file-excel" />
                          &nbsp; EXCEL
                        </button>
                      }
                      filename={"Pedidos"}
                    >
                      <ExcelSheet dataSet={dataSetExcel} name="Pedidos" />
                    </ExcelFile>
                  </div>
                </div>
                <div className="white-space-16"></div>
                {this.state.loading ? (
                  <React.Fragment>
                    <div className="white-space-32"></div>
                    <div className="row justify-center">
                      <i className="fas fa-spinner fa-spin"></i>
                    </div>
                  </React.Fragment>
                ) : !this.state.empty ? (
                  <React.Fragment>
                    <div
                      className="card-table card-owners column"
                      id="card-owners"
                    >
                      <div className="table-responsiv column">
                        <table>
                          <thead>
                            <tr className="text-left">
                              {columns.map((column, index) => (
                                <th
                                  key={index}
                                  style={{
                                    textAlign:
                                      column === "Cantidad" ? "right" : "left"
                                  }}
                                >
                                  {column}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {dataSet
                              .slice(
                                (this.state.page - 1) * 10,
                                this.state.page * 10
                              )
                              .map((row, index) => {
                                return row;
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {dataSet.length > 10 ? (
                      <React.Fragment>
                        <div className="white-space-16"></div>
                        <Paginador
                          pages={Math.ceil(dataSet.length / 10)}
                          setPage={this.setPage.bind(this)}
                        />
                      </React.Fragment>
                    ) : null}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <div className="white-space-48"></div>
                    <div className="justify-center">
                      <h3>No se encontraron resultados.</h3>
                    </div>
                  </React.Fragment>
                )}
                <div className="white-space-32"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Consumer(Orders);
