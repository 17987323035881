import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Header from "../components/header/header";
import moment from "moment";
import Request from "../core/httpClient";
import { Consumer } from "../context";
import Chart from "react-chartjs-2";
const req = new Request();

class Interactions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: moment()
        .add(-7, "day")
        .format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      empty: true,
      user: {
        idProveedor: 0,
        idServicio: 0,
        nombre: "",
        titulo: "",
        descripcion: "",
        telefono: "",
        whatsapp: "",
        web: "",
        igate: false,
        calificacion: 0,
        ciudad: "",
        logo: "",
        usuario: ""
      },
      dataResult: [],

      typeChartPeticiones: "",
      dataChartPeticiones: {},

      typeChartLlamadas: "",
      dataChartLlamadas: {},

      typeChartWhatsapp: "",
      dataChartWhatsapp: {},

      typeChartWeb: "",
      dataChartWeb: {},

      typeChartPedidos: "",
      dataChartPedidos: {},

      optionsChart: {},
      maxValue: 6
    };
  }

  async componentDidMount() {
    await this.load();
    await this.setOptionChart(this.state.maxValue);
    await this.getReport();
  }

  async load() {
    const user = await this.props.context.loadUser();

    if (user) {
      this.setState({
        user: {
          idProveedor: user.id.id_Proveedor,
          idServicio: user.id.id_Servicio,
          nombre: user.id.Nombre,
          titulo: user.id.Titulo,
          descripcion: user.id.Descripcion,
          telefono: user.id.Telefono,
          whatsapp: user.id.Whatsapp,
          web: user.id.Web,
          igate: user.id.iGate,
          calificacion: user.id.Calificacion,
          ciudad: user.id.Ciudad,
          logo: user.id.Logo,
          usuario: user.id.Usuario
        }
      });
    }
  }

  startChange(event) {
    this.setState({ startDate: event.target.value });
  }

  endChange(event) {
    this.setState({ endDate: event.target.value });
  }

  async getReport(event) {
    if (event) {
      event.preventDefault();
    }

    this.setState({ loading: true });

    const data = {
      start: this.state.startDate,
      end: this.state.endDate,
      idProveedor: this.state.user.idProveedor
    };

    const res = await req.post("/servicios/getPeticiones", data);

    if (res.reports) {
      this.setState({ dataResult: res.reports, empty: false });
      this.setChartPeticiones();
      this.setChartLlamadas();
      this.setChartWhatsapp();
      this.setChartWeb();
      this.setChartPedidos();
      this.setState({ loading: false });
    }

    if (res.empty) {
      this.setState({ empty: res.empty, loading: false });
    }
  }

  async setChartPeticiones() {
    let labelsChart = [];
    let dsChart = [];
    let dsLlamadas = 0;
    let dsWhatsapp = 0;
    let dsweb = 0;
    let dsPedidos = 0;

    if (this.state.dataResult[0]) {
      labelsChart = Object.keys(this.state.dataResult[0]);
    }

    labelsChart = labelsChart.splice(1, labelsChart.length);

    this.state.dataResult.map(row => {
      return (
        (dsLlamadas += row.Llamadas),
        (dsWhatsapp += row.Whatsapp),
        (dsweb += row.Web),
        (dsPedidos += row.Pedidos)
      );
    });

    dsChart.push(dsLlamadas);
    dsChart.push(dsWhatsapp);
    dsChart.push(dsweb);
    dsChart.push(dsPedidos);

    this.setState({
      typeChartPeticiones: "bar",
      dataChartPeticiones: {
        labels: labelsChart,
        datasets: [
          {
            data: dsChart,
            backgroundColor:
              process.env.REACT_APP_NAME === "iGate"
                ? "rgba(234, 66, 53, 1.0)"
                : "rgba(234, 84, 95, 1.0)"
          }
        ]
      }
    });
  }

  async setChartLlamadas() {
    let labelsChart = [];
    let dsChart = [];

    this.state.dataResult.map(row => {
      labelsChart.push(row.Fecha);
      dsChart.push(row.Llamadas);

      if (row.Llamadas > this.state.maxValue) {
        this.setOptionChart(row.Llamadas);
      }

      return "OK";
    });

    this.setState({
      typeChartLlamadas: "line",
      dataChartLlamadas: {
        labels: labelsChart,
        datasets: [
          {
            data: dsChart,
            fill: false,
            lineTension: 0
          }
        ]
      }
    });
  }

  async setChartWhatsapp() {
    let labelsChart = [];
    let dsChart = [];

    this.state.dataResult.map(row => {
      labelsChart.push(row.Fecha);
      dsChart.push(row.Whatsapp);

      if (row.Whatsapp > this.state.maxValue) {
        this.setOptionChart(row.Whatsapp);
      }

      return "OK";
    });

    this.setState({
      typeChartWhatsapp: "line",
      dataChartWhatsapp: {
        labels: labelsChart,
        datasets: [
          {
            data: dsChart,
            fill: false,
            lineTension: 0
          }
        ]
      }
    });
  }

  async setChartWeb() {
    let labelsChart = [];
    let dsChart = [];

    this.state.dataResult.map(row => {
      labelsChart.push(row.Fecha);
      dsChart.push(row.Web);

      if (row.Web > this.state.maxValue) {
        this.setOptionChart(row.Web);
      }

      return "OK";
    });

    this.setState({
      typeChartWeb: "line",
      dataChartWeb: {
        labels: labelsChart,
        datasets: [
          {
            data: dsChart,
            fill: false,
            lineTension: 0
          }
        ]
      }
    });
  }

  async setChartPedidos() {
    let labelsChart = [];
    let dsChart = [];

    this.state.dataResult.map(row => {
      labelsChart.push(row.Fecha);
      dsChart.push(row.Pedidos);

      if (row.Pedidos > this.state.maxValue) {
        this.setOptionChart(row.Pedidos);
      }

      return "OK";
    });

    this.setState({
      typeChartPedidos: "line",
      dataChartPedidos: {
        labels: labelsChart,
        datasets: [
          {
            data: dsChart,
            fill: false,
            lineTension: 0
          }
        ]
      }
    });
  }

  async setOptionChart(newMaxValue) {
    this.setState({
      maxValue: newMaxValue,
      optionsChart: {
        elements: {
          line: {
            borderColor:
              process.env.REACT_APP_NAME === "iGate"
                ? "rgba(234, 66, 53, 1.0)"
                : "rgba(234, 84, 95, 1.0)"
          }
        },
        tooltips: {
          callbacks: {
            title: function(tooltipItem, data) {
              return "";
            },
            label: function(tooltipItem, data) {
              return tooltipItem.label + " - " + tooltipItem.value;
            }
          },
          backgroundColor:
            process.env.REACT_APP_NAME === "iGate"
              ? "rgba(234, 66, 53, 1.0)"
              : "rgba(234, 84, 95, 1.0)",
          displayColors: false
        },
        legend: {
          display: false
        },
        scales: {
          xAxes: [
            {
              ticks: {
                maxTicksLimit: 7,
                maxRotation: 0
              }
            }
          ],
          yAxes: [
            {
              ticks: {
                maxTicksLimit: 7,
                min: 0,
                max: newMaxValue
                //stepSize: 1
              }
            }
          ]
        }
      }
    });
  }

  render() {
    return (
      <div className="admin interactions column">
        <Helmet>
          <title>{process.env.REACT_APP_NAME} - Interacciones</title>
        </Helmet>
        <Header nav={"interacciones"} />
        <div className="row justify-center">
          <div className="column" id="content">
            <div className="justify-center">
              <div className="container column text-justify">
                <div className="white-space-32"></div>
                <div className="navigation align-center">
                  <div className="justify-start">
                    <h3 className="weight-semi">Interacciones</h3>
                  </div>
                </div>
                <div className="white-space-8"></div>
                <div className="filter-date row-responsive">
                  <form
                    className="get-reports row-responsive"
                    onSubmit={this.getReport.bind(this)}
                  >
                    <div className="data-from column">
                      <h5 className="color-black">DESDE:</h5>
                      <div className="white-space-8" />
                      <input
                        type="date"
                        id="startdate"
                        name="startdate"
                        className="input input-text"
                        placeholder="Desde"
                        value={this.state.startDate}
                        onChange={this.startChange.bind(this)}
                      />
                    </div>
                    <div className="data-to column">
                      <h5 className="color-black">HASTA:</h5>
                      <div className="white-space-8" />
                      <input
                        type="date"
                        id="enddate"
                        name="enddate"
                        className="input input-text"
                        placeholder="Hasta"
                        value={this.state.endDate}
                        onChange={this.endChange.bind(this)}
                      />
                    </div>
                    <div className="get-result justify-start align-end">
                      <button className="btn btn-filter btn-small">
                        {!this.props.loading ? (
                          <i className="fas fa-download" />
                        ) : (
                          <i className="fas fa-spinner fa-spin" />
                        )}
                        &nbsp; OBTENER
                      </button>
                    </div>
                  </form>
                  <div className="get-results justify-end align-end"></div>
                </div>
                <div className="white-space-16"></div>
                {this.state.loading ? (
                  <React.Fragment>
                    <div className="white-space-32"></div>
                    <div className="row justify-center">
                      <i className="fas fa-spinner fa-spin"></i>
                    </div>
                  </React.Fragment>
                ) : !this.state.empty ? (
                  <React.Fragment>
                    <div className="grid-charts full">
                      <div className="card-table ">
                        <div className="row justify-start align-center">
                          <i className="fas fa-phone fa-flip-horizontal"></i>
                          <h4 className="weight-semi">Llamadas</h4>
                        </div>
                        <Chart
                          type={this.state.typeChartLlamadas}
                          data={this.state.dataChartLlamadas}
                          options={this.state.optionsChart}
                        />
                      </div>

                      <div className="card-table ">
                        <div className="justify-start align-center">
                          <i className="fab fa-whatsapp"></i>
                          <h4 className="weight-semi">Whatsapp</h4>
                        </div>
                        <Chart
                          type={this.state.typeChartWhatsapp}
                          data={this.state.dataChartWhatsapp}
                          options={this.state.optionsChart}
                        />
                      </div>

                      <div className="card-table ">
                        <div className="row justify-start align-center">
                          <i className="fas fa-laptop"></i>
                          <h4 className="weight-semi">Web</h4>
                        </div>
                        <Chart
                          type={this.state.typeChartWeb}
                          data={this.state.dataChartWeb}
                          options={this.state.optionsChart}
                        />
                      </div>

                      <div className="card-table ">
                        <div className="row align-center justify-start">
                          <i className="fas fa-boxes"></i>
                          <h4 className="weight-semi">Pedidos</h4>
                        </div>
                        <Chart
                          type={this.state.typeChartPedidos}
                          data={this.state.dataChartPedidos}
                          options={this.state.optionsChart}
                        />
                      </div>
                    </div>
                    <div className="white-space-16"></div>
                    <div className="card-table-big ">
                      <div className="row align-center justify-start">
                        <i className="fas fa-list-ul"></i>
                        <h4 className="weight-semi">Peticiones</h4>
                      </div>
                      <Chart
                        type={this.state.typeChartPeticiones}
                        data={this.state.dataChartPeticiones}
                        options={this.state.optionsChart}
                      />
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <div className="white-space-48"></div>
                    <div className="justify-center">
                      <h3>No se encontraron resultados.</h3>
                    </div>
                  </React.Fragment>
                )}
                <div className="white-space-32"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Consumer(Interactions);
