/**
 *  index.js
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO
 *  @description: Punto de Entrada de React
 * 	@process: 1
 */

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "react-app-polyfill/ie11";
import WebFont from "webfontloader";

WebFont.load({
  google: {
    families: ["Mukta:400,500,600,700", "sans-serif"]
  }
});

ReactDOM.render(<App />, document.getElementById("root"));
serviceWorker.unregister();
